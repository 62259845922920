
fieldset {
  border: 1px solid $app-secondary;
  padding: 16px;
  margin-bottom: 16px;

  legend {
    width: auto;
    padding: 0 8px;
    font-size: 16px;
    color: $app-secondary;
  }
}
