// ---------------------------------------------------------------------- >
// ~ SEARCH HELP
// ---------------------------------------------------------------------- >
// Hierarchy :
// ~  : High level section. Title is in summary, separate by "arrow" at the begin and the end (except search help and summary).
// ==== : Upper level in a section
// == : Sub level in a section
// = : Lower level in a section

// ---------------------------------------------------------------------- >
// ~ SUMMARY
// ---------------------------------------------------------------------- >
// 1 - MISC

// ---------------------------------------------------------------------- >
// ~ MISC
// ---------------------------------------------------------------------- >

// ==== Define Fonts
$body-font : 'Open Sans', Arial, sans-serif;

// ==== Define Colors
// == Colors by name (based on : http://chir.ag/projects/name-that-color/)
// USE ONLY TO DEFINE COLORS BY FUNCTION, don't use in others vars declaration.
$nexia-light-teal :                             #00b2a9 !default;
$nexia-dark-teal :                              #0d5257 !default;
$nexia-grey :                                   #575756 !default;
$nexia-light-grey :                             #969494 !default;
$nexia-extra-light-grey :                       #e7e2e2 !default;

$nexia-light-blue :                             #1584c2 !default;
$nexia-dark-blue :                              #2b52a0 !default;

$nexia-purple :                                 #841262 !default;
$nexia-pink :                                   #ca0e63 !default;

$nexia-warm-grey :                              #ded1c0 !default;

$limeade :                                      #3cab1e !default;
$sun :                                          #F39C11 !default;
$monza :                                        #EE5453 !default;
$nexia-danger :                                        #d32f2f !default;

$wild-sand :                                    #f5f5f5 !default;

// == Colors by function
// = General
$app-primary :                                  $nexia-light-teal !default;
$app-secondary :                                $nexia-dark-teal !default;

$app-grey :                                     $nexia-grey !default;
$app-light-grey :                               $nexia-light-grey !default;
$app-extra-light-grey :                         $nexia-extra-light-grey !default;

$app-alt-color-blue :                           $nexia-light-blue !default;
$app-alt-color-dark-blue :                      $nexia-dark-blue !default;
$app-alt-color-purple :                         $nexia-purple !default;
$app-alt-color-pink :                           $nexia-pink !default;
$app-alt-color-warm-grey :                      $nexia-warm-grey !default;

$app-error :                                    $monza !default;
$app-warning :                                  $sun !default;
$app-success :                                  $limeade !default;

$app-disabled :                                 mix($app-grey, white, 25%) !default;

// = Fonts color
$body-font-color :                              $app-grey !default;
$body-bg-color :                                $wild-sand !default;

// ==== PATHS
$img-path :                                     '../assets/images/' !default;
$png-path :                                     '../assets/images/png/' !default;
$svg-path :                                     '../assets/images/svg/' !default;
$fa-font-path:                                  '../../node_modules/font-awesome/fonts' !default;
$font-path :                                    '../assets/fonts/' !default;

// < ----------------------------------------------------------------------
// ~ MISC END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ HEADER
// ---------------------------------------------------------------------- >

$header-height :                                76px !default;

// < ----------------------------------------------------------------------
// ~ HEADER END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ FORM RADIO
// ---------------------------------------------------------------------- >

$form-radio-metrics :                           20px !default;
$form-radio-border-color :                      $app-secondary !default;
$form-radio-bg-color :                          white !default;
$form-radio-check-color :                       $app-primary !default;
$form-radio-disabled-border-color :             $app-disabled !default;
$form-radio-border-radius :                     20px !default;

// < ----------------------------------------------------------------------
// ~ FORM RADIO END
// < ----------------------------------------------------------------------


// ---------------------------------------------------------------------- >
// ~ FORM CHECKBOX
// ---------------------------------------------------------------------- >

$form-checkbox-metrics :                           20px !default;
$form-checkbox-border-color :                      $app-secondary !default;
$form-checkbox-bg-color :                          white !default;
$form-checkbox-check-color :                       $app-primary !default;
$form-checkbox-disabled-border-color :             $app-disabled !default;
$form-checkbox-border-radius :                     3px !default;

// < ----------------------------------------------------------------------
// ~ FORM CHECKBOX END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ STATUS WITH ACTOR
// ---------------------------------------------------------------------- >

$statusWithActor-status-color : (
  'isPending' : $app-alt-color-warm-grey,
  'isRunning' : $app-primary,
  'isValidated' : $app-success
) !default;

// < ----------------------------------------------------------------------
// ~ STATUS WITH ACTOR END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ File upload button and image preview
// ---------------------------------------------------------------------- >

$image-preview-metric : 130px !default;

// < ----------------------------------------------------------------------
// ~ File upload button and image preview END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ FIXED HEADER TABLE
// ---------------------------------------------------------------------- >
$table-bg-color :                                      white !default;
$table-heading-bg :                                    $app-secondary !default;
$table-heading-color :                                 white !default;
$table-even-row-bg-color :                             mix($app-primary, white, 10%) !default;
$table-cell-padding :                                  8px !default;
$table-fix-header-heading-height :                     37px !default;

$table-fix-header-height-small :                       100px !default;
$table-fix-header-height-medium :                      250px !default;
$table-fix-header-height-large :                       500px !default;

// < ----------------------------------------------------------------------
// ~ FIXED HEADER TABLE END
// < ----------------------------------------------------------------------
