@import '../../../styles/utils/variables';

.tableCompositeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 38vh;
}

.blockContainer {
  min-height: auto;
}

.indiceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datePickerContainer {
  display: flex;
  align-items: center;
  margin: 16px;

  p {
    margin-right: 8px;
    margin-bottom: 0;
  }
}

.buttonContainer {
  text-align: end;
  margin: 16px 0 0 0;
}

.newComposite {
  margin-left: 295px;

  &-content {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}
