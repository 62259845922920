// -------- CUSTOM DATE PICKER
//

.ant-calendar-picker,
.beforeDatePicker,
.betweenDatePicker {
  margin-right: 8px;
}


.ant-input:hover, :focus, :active {
  border-color: $app-primary !important;
}
