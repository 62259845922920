@import '../../../styles/utils/variables';

.conversionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.conversionImg {
  background-image: url("../../../assets/images/png/edit_img.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  height: 550px;
  width: 100%;
}

.buttonContainer {
  .right-8 {
    margin-right: 8px;
  }
}
