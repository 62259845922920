// -------- CUSTOM CALENDAR
//


// -------- Input calendar skin
.ant-calendar-picker-input {
  height: 45px;
}

.ant-calendar-picker-clear {
  background: #e6e6e6;
}



// -------- Calendar items skin
.ant-calendar-today .ant-calendar-date {
  border-color: $app-secondary;
  color: $app-secondary;
}

.ant-calendar-selected-date .ant-calendar-date {
  background-color: $app-secondary;
  color: white;

  &:hover, &:focus {
    transition: 240ms;
    opacity: 0.6;
    background-color: $app-secondary;
    color: white;
  }
}

.ant-calendar .ant-calendar-ok-btn {
  background-color: $app-primary;
  border-color: $app-primary;
  color: white !important;
  box-shadow: 0 16px 24px rgba($app-primary, 0.2);

  &:hover, &:focus {
    transition: 240ms;
    opacity: 0.6;
    background-color: $app-primary;
  }
}
