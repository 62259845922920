@import '../../styles/utils/variables';

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  background-image: url("../../assets/images/png/login_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 116px;
    background: white;
    padding: 54px;
    width: 30vw;
    box-shadow: 0 0 8px $app-extra-light-grey;

    h1 {
      font-weight: bold;
      color: $app-primary;
      margin-bottom: 8px;
      font-size: 30px;
    }

    p, h1 {
      text-align: center;
    }

    p {
      color: $app-light-grey;
      margin-bottom: 54px;
    }
  }

  &-id {
    margin-bottom: 32px;
  }

  &-forgetPassword {
    text-decoration: underline;
    color: $app-primary;
    font-size: 12px;
    cursor: pointer;
  }
}

.buttonContainer {
  text-align: end;
  margin-top: 54px;
}

