@import '../../styles/utils/variables';

:host {
  display: block;
}

.home {

  &-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 16px;
      margin: 24px;
      height: 110px;
      min-width: 18vw;
      background: white;
      box-shadow: 0 0 12px rgba($app-primary, 0.1);
      overflow: hidden;

      i {
        position: absolute;
        left: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($nexia-pink, 0.6);
        box-shadow: 0 0 16px rgba($nexia-pink, 0.3);
        color: white;
        border-radius: 100%;
        height: 40px;
        width: 40px;
        padding: 12px;
      }

      &-numberContainer {
        width: 70%;
        text-align: center;
      }

      &-number {
        font-size: 40px;
        font-weight: bold;
        color: $app-secondary;
      }
    }
  }


  &-tableContainer {
    background: white;
    padding: 16px;
    min-height: 55vh;
    border-radius: 4px;
  }

  &-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-item {
      margin: 24px 12px;
      background: white;
      border: 1px solid $app-primary;
      color: $app-primary;

      &:hover {
        background: $app-primary;
        box-shadow: 0 16px 24px rgba($app-secondary, 0.2);
        color: white;
      }

      &.selected {
        background: $app-primary;
        box-shadow: 0 16px 24px rgba($app-secondary, 0.2);
        color: white;
      }
    }
  }
}

.blockContainer {
  min-height: calc(100vh - 360px);
}
