:host {
  display: block;
}

.selectImportContainer {
  .textHelper {
    padding: 0 24px;
  }

  .form-container {
    padding: 24px;
  }

  p {
    margin-bottom: 0;
  }
  
  input {
    margin-bottom: 0 !important;
  }

  &-content {
    display: flex;
    align-items: center;
    margin: 16px;
  }
}

