body {
  height: calc(100vh - 70px);
}

p, label {
  font-size: 14px;
}

.margin-right-1 {
  margin-right: 1em !important;
}

.margin-left-1 {
  margin-left: 1em !important;
}

.margin-top-1 {
  margin-top: 1em !important;
}

.margin-bottom-1 {
  margin-bottom: 1em !important;
}

.max-width {
  width: 100%;
}

