// -------- CUSTOM INPUT
//
// -------- Input search select tag
form {
  border-radius: 0;

  label {
    margin-bottom: 4px;
    font-size: 14px;
    color: $app-light-grey;

    &.isDisabled {
      background-color: rgba($light-gray, 0.3);
      color: $medium-gray;
      border: rem-calc(1px) solid rgba($medium-gray, 0.3);
      border-right: 0;
    }
  }
}

.ant-input-affix-wrapper {
  width: 40vw;
}

.inputContainer {
  display: flex;
  justify-content: flex-end;
}

input[readonly] {
  background: white;
  cursor: inherit;
}

// -------- Input select

textarea {
  margin-bottom: 0;
}

select {
  border-radius: 0;
  padding: rem-calc(2px 8px);
}

.input-select {
  color: $body-font-color;
  padding: 4px 32px 4px 16px;
  cursor: pointer;
}

// -------- Input form

.input-form {
  &:focus {
    border-color: $app-primary;
  }

  input.ng-invalid {
    border: 2px solid $app-error;
    padding-right: 5px;
  }
}

input[required] + .label-text:after {
  color: #c00;
  content: " *";
  font-family: serif;
}


// -------- Input Style Types

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
input[type='mail'],
textarea,
select {
  border: 1px solid $app-extra-light-grey !important;
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(10, 10, 10, 0.3) !important;
  color: $body-font-color !important;
  font-size: rem-calc(14px) !important;

  &:focus {
    border: 1px solid $app-primary !important;
    box-shadow: 0 0 2px 0 $app-primary !important;
    transition: all 250ms ease-in-out;
  }

  // --- Type Invalid

  &.ng-invalid {
    border: 1px solid $app-error;
    background-color: $app-error;

    &:focus {
      border-color: $app-error;
      box-shadow: inset 0 0 2px $app-error;
    }
  }

  &:disabled {
    background-color: $light-gray;
    box-shadow: inset 0 0 2px rgb(170, 162, 160);
    color: $app-grey;
  }
}

input[type='number'] {
  padding: rem-calc(8px 4px) !important;
}

select {
  padding-right: 32px !important;
}

// -------- Input selected Types

.selected {
  background-color: $app-primary;
}

// -- Conditional Input Types

.type-input {
  display: inline-block;
  vertical-align: top;
}

// -- Style Input Types

.type-input {
  display: none;
}








