@media print {
  body {
    background: white !important;
  }

  .App {
    background: transparent !important;
  }

  .header {
    height: inherit !important;
  }

  .main-header {
    position: relative !important;
  }

  // ==== Audit Result

  .auditResult {
    .blockContainer {
      flex-direction: column;
      padding: 0;

      fieldset {
        width: 100%;
        max-width: inherit;
        padding-top: 0;

        .row {
          margin: 0;
        }

        .columns {
          margin-bottom: 0 !important;
          font-size: 9pt !important;
        }

        .aboutMessage {
          margin-top: 0;
        }
      }
    }

    .table-opc-audit {
      height: inherit;
      overflow: visible;

      thead {
        display:table-header-group;
        margin-top:50px;

        tr {
          background: white;

          th {
            padding-left: 0;
            line-height: 1;
            font-size: 6pt !important;
          }
        }
      }

      tbody tr:nth-child(even) {
        background-color: rgba($nexia-dark-teal, 0.1) !important;
      }

      tbody {


        tr {
          border-bottom: $nexia-light-grey !important;

          td {
            padding: 0;
            background: transparent !important;
            font-size: 7pt !important;
          }
        }
      }
    }
  }
}


