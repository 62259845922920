@import '../../../styles/utils/variables';

.flex {
  display: flex;

  .opc-column-1,
  .opc-column-2 {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: calc(75% / 2);
  }
}

.opc-column-3{
  margin: 8px 16px 16px 16px;
  padding: 8px 16px;
  background: $app-primary;
  border-radius: 4px;
  height: 12%;
  width: 25%;

  label {
    color: white;
  }
}

.blockContainer {
  min-height: auto;
}

.buttonContainer {
  margin: 8px 16px;
}

.margin-right {
  margin-right: 8px;
}

.switchButton {
  margin-left: 16px;
}

.flexButtonContainer {
  display: flex;
  justify-content: space-between;
}
