@import '../../../styles/utils/variables';

.buttonForgetPass {
  .ant-btn {
    margin: 0;
    transition: 240ms;
    cursor: pointer;
    border-radius: 0;
    line-height: normal;
    outline: none;
    padding: 0;
    font-size: inherit;
    border: none;
    background: transparent;
    color: $app-primary;
  }
}

.passwordContainer {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 30%;
  }

  p {
    color: $app-secondary;
  }
}
