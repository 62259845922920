@import '../../../styles/utils/variables';

.padding {
  padding: 0 8px;
}

.margin-left-20px {
  margin-left: 20px;
}

.table-opc-audit {
  height: 350px;

  tbody {
    tr:nth-child(1),
    tr:nth-child(2) {
      background-color: rgba($nexia-dark-blue, 0.3);
      border: 1px solid white;
    }
  }
}

.rightContainer {
  background: rgba($app-secondary, 0.03);
}

.aboutDate {
  margin-left: 8px;
}

.aboutDateSecond {
  margin-left: 16px;
}

.aboutMessage {
  margin-top: 16px;
  width: auto;
  background: rgba($app-error, 0.2);
  color: $app-error;
  border-radius: 4px;
  padding: 8px 16px;
  text-align: left;
  margin-bottom: 0;

  i {
    margin-right: 8px;
  }

  span {
    padding: 0;
  }
}

