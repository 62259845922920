@import '../../../styles/utils/variables';

.previsualisation-modal-container {
  height: 100vh;

  .previsualisation-modal-content {
    background-color: white;
    border-radius: 4px;
    overflow: auto;
    max-height: 70vh;
    position: relative;
    .previsualisation-modal-file-container {
      margin-bottom: 2em;
      .file-item {
        .MuiTableCell-head {
          color: white;
          font-weight: bold;
        }
      }

      .file-name {
        padding: 1em;
      }
    }
    .modal-title {
      font-size: 1.6em;
      font-weight: bold;
      margin-bottom: 1em;
    }

    .close-modal-button {
      position: absolute !important;
      right: 2em;
      top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba($app-secondary, 0.2);
      color: $app-secondary;
      border-radius: 100%;

      &:hover {
        opacity: 0.8;
      }
    }
    width: 85vw;
  }
}

.file-name {
  margin-bottom: 24px;
}

// Table Content
.MuiTableCell-head {
  color: white !important;
  line-height: inherit;
}

.MuiTableCell-body {
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 300;
}

.MuiTableCell-sizeSmall {
  padding: 14px 40px 14px 16px;
}

.MuiTableCell-root {
  border-bottom: none !important;
  color: rgba(0, 0, 0, 0.65);
  line-height: inherit;
  letter-spacing: inherit;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
