@import '../styles/utils/variables';

.nav-cover {
  position: fixed;
  z-index: var(--navcover-z-index);
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
}

.side-nav {
  height: 100%;
  width: 0;  /* to be set in js */
  position: fixed;
  overflow-x: hidden;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, $app-secondary 75%, black 145%);
  padding-top: 7rem;
  transition: 0.5s;
  z-index: 999;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($app-primary, 0.3);
    height: 32px;
    width: 32px;
    margin-right: 8px;
    border-radius: 100%;
  }
}

.side-nav a {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 1.5rem;
  color: rgba(white, 0.8);
  transition: 0.53s;
  margin-left: 16px;
  border-radius: 28px 0 0 28px;
}

.side-nav {
  a:hover {
    background: rgba(white, 0.1);
    color: white;
    font-weight: 500;
    border-radius: 28px 0 0 28px;
    margin-left: 16px;
    opacity: 1;
    padding: 8px 16px;

    i {
      background: $app-primary;
    }
  }
}

.side-nav .close-nav {
  position: absolute;
  top: 0;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: rgba(white, 0.3);
  color: white;
  margin-top: 8px;
  cursor: pointer;
}

.site-wrapper {
    transition: margin-left .5s;
}

.open-nav {
  font-size: 2rem;
  cursor: pointer;
  position: fixed;
  left: 1em;
  top: 1em;
  z-index: 101;
}
