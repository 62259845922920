// ~ BASE
// ====================================================================== >
@import 'styles/base/base';

// ~ VENDORS
// ====================================================================== >
@import '~foundation-sites/scss/foundation';
@import 'styles/vendors/foundation-variables';
// https://foundation.zurb.com/sites/docs/sass.html#adjusting-css-output
@include foundation-everything;
@include foundation-flex-classes;
@include foundation-flex-grid;
@import 'styles/vendors/font-icons';

// ~ UTILS
// ====================================================================== >
@import 'styles/utils/variables';
@import 'styles/utils/antDesignCustom';
@import 'styles/utils/helpers';
@import 'styles/utils/print';


// ~ COMPONENTS
// ====================================================================== >
@import 'styles/components/headerMenu';
@import 'styles/components/forms';
@import 'styles/components/button';
@import 'styles/components/content';
@import 'styles/components/datePicker';
@import 'styles/components/table';
@import 'styles/components/calendar';
@import 'styles/components/popup';
@import 'styles/components/checkbox - switch';
@import 'styles/components/fieldset';
@import 'styles/components/spiner';


// ~ PAGES
// ====================================================================== >
