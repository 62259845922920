@import '../../styles/utils/variables';

:host {
  display: block;
}

.menuSwitch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid $app-extra-light-grey;
  border-radius: 8px 0 0 8px;
  padding: 0;
  min-width: 270px;
  z-index: 1;

  &-item {
    padding: 16px 8px;
    background: white;
    outline: none;

    &:first-child {
      border-radius: 8px 0 0 0;
    }

    &:hover,
    &.active {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(135deg, $app-secondary 0%, $app-primary 100%);
      color: white;
      cursor: pointer;
      width: 100%;

      &:after {
        content: '\ea3c';
        font-family: 'Acanexia';
        color: white;
        margin-right: 8px;
        margin-left: 24px;
      }
    }
  }

  .selected {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(135deg, $app-secondary 0%, $app-primary 100%);
    color: white;
    width: 100%;

    &:after {
      content: '\ea3c';
      font-family: 'Acanexia';
      color: white;
      margin-right: 8px;
      margin-left: 24px;
    }
  }
}

.switchContainer {
  padding: 0;

  &-content {
    padding: 0;

    &-subtitle {
      font-weight: 600;
      background: rgba($app-secondary, 0.1);
      padding: 8px 24px;
      margin: 16px 0;
    }
  }
}

.blockWrapper {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 260px);
  width: calc(100% - 270px);
}

// ---- DopZone

.DropZoneArea-display-custom {
  width: 100%;
  padding: 0 24px;

  .MuiGrid-item {
    position: relative;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
    background: white;
    padding: 0 8px !important;
    margin: 16px !important;
    border-radius: 50px !important;
    border: 1px solid $app-primary;

    svg.MuiSvgIcon-root {
      width: 30px;
      height: 30px;
      box-shadow: none;
      color: black;
      margin: 0;
    }

    &:first-child {
      margin-left: 40px;
    }

    &:hover {
      background: $app-primary;

      p {
        color: white !important;
      }

      button.MuiFab-root:before {
        color: white;
      }
      svg.MuiSvgIcon-root {
        opacity: 1;
        color: white;
      }
    }
  }

  .MuiGrid-container > span {
    display: none;
  }
}

.DropzoneArea-dropZone-2 {
  min-height: 110px !important;
  border: 1px solid $app-extra-light-grey !important;
  border-radius: 8px;
  background-color: rgba($app-extra-light-grey, 0.1) !important;
}

.DropzoneArea-dropzoneTextStyle-5 {
  min-height: 110px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.DropzoneArea-uploadIconSize-6 {
  color: $app-primary !important;
}

.DropzoneArea-dropzoneParagraph-7 {
  margin: 0;
  transition: 240ms;
  font-size: 14px !important;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 32px;
  color: white;
  line-height: normal;
  outline: none;
  background-color: $app-primary;
  box-shadow: 0 16px 24px rgba($app-primary, 0.2);

  &:hover,
  &:focus {
    transition: 240ms;
    opacity: 0.6;
    background-color: $app-primary;
    color: white;
  }
}

//---- Files upload
.PreviewList-imageContainer-26:hover .PreviewList-removeBtn-24 {
  opacity: 0;
}

.PreviewList-removeBtn-24 {
  position: initial;
}

.PreviewList-smallPreviewImg-25 {
  display: none !important;
}

.MuiGrid-container {
  justify-content: center;
}

.MuiGrid-item {
  p {
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    font-size: 12px;
    text-overflow: ellipsis;
    padding: 0 1em;
    color: $app-primary;
  }
}

.MuiGrid-spacing-xs-8 {
  width: inherit !important;
  margin: 0 !important;
}

.import-helper-text {
  padding: 0 24px 16px 24px;
}

.MuiGrid-grid-xs-4 {
  flex-basis: inherit !important;
}

//---- Button delete files upload
button.MuiFab-root {
  z-index: 10;
  background-color: transparent;
  box-shadow: none;
  color: $app-grey;
  opacity: 1;
  height: 40px;
  width: 40px;
  position: relative;
  padding: 0;
  top: 0;
  right: 0;

  &:before {
    content: '\ea0f';
    font-family: Acanexia;
    font-size: 10px;
    color: $app-primary;
  }

  &:hover {
    opacity: 0.5 !important;
    background-color: transparent;
  }
}

.MuiFab-label {
  display: none !important;
}

.PreviewList-removeBtn-24 {
  position: inherit !important;
  right: inherit !important;
  top: inherit !important;
}

.import-buttons-container {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

//---- Checkbox
.checkboxWrapper {
  padding: 16px 24px;
}
