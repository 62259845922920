@import '../../../styles/utils/variables';

.subtitle {
  margin-bottom: 16px;
}

.rightSeparator {
  border-right: 1px solid $app-secondary;
}

.aboutContainer {
  margin-right: 40px;
}

.infoContainer {
  margin-bottom: 8px;
}

.aboutData {
  font-weight: 600;
}

.buttonContainer {
  margin-top: 10px;
}

.rightContainer {
  background: rgba($app-secondary, 0.03);
}

.datePickerContainer {
  justify-content: inherit;
  margin: 0;

  &.first {
    margin-bottom: 32px;
  }

  &.second {
    margin-bottom: 8px;
  }
}

.buttonPosition {
  display: flex;
  align-items: center;
  text-align: end;
}


.table-opc-audit {
  overflow: hidden;
  overflow-y: scroll;
  height: 400px;
}
