@import '../styles/utils/variables';

.headerContainer {
  height: 70px;
}

.ant-page-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100vw;
  padding: 8px;
  box-shadow: 0 0 24px $app-extra-light-grey;

  &-ant-page-header-title-view {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}

.main-header {
  height: 70px;
  overflow: hidden;
  background: white;
  box-shadow: 0 0 16px #ede9e9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  .header-logout-link {
    position: absolute;
    right: 1em;
  }

  .headerMenu-logo {
    height: 80%;
    width: 130px;
    margin: auto;
    margin-top: 10px;
  }
}
