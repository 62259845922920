// CUSTOM SPINER
//

// -----------------------------------------------------------------------------
// This file contains all styles related to the SPINER component.
// -----------------------------------------------------------------------------

.ant-spin-dot {
    position: absolute;
    left: 50%;
}

.ant-spin-dot-item {
    background-color: $nexia-light-teal;
}