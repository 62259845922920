:host {
  display: block;
}

.conversion-page-header {
  flex-direction: column;
}

.actionElement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 24px 0;
}
