@import '../../styles/utils/variables';

// Checkbox Skin //
// ----------//

//---- Check color //
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $app-primary;
  border: 1px solid $app-primary;
}



// Switch Skin //
// ----------//
.ant-switch {
  border-radius: 50px !important;
}

.ant-switch-checked {
  background-color: $app-primary;
}
