// -------- CONTENT
//

.App {
  margin: 24px 54px;
  &.login-page {
    padding-top: 0;
    margin: 0;
  }
}

.header {
  height: 68px;
}

.title {
  color: black;
  font-weight: bold;
  margin-bottom: 24px;

  &:before {
    content: "";
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    background: rgba($app-primary, 0.3);
    height: 34px;
    width: 34px;
    border-radius: 100%;
    position: absolute;
    z-index: -1;
    left: 40px;
    top: 85px;
  }
}

.subtitle {
  font-weight: 600;
  background: rgba(13, 82, 87, 0.1);
  padding: 8px 24px;
}

.blockContainer {
  background: white;
  margin-top: 24px;
  padding: 16px;
  box-shadow: 0 0 16px #ede9e9;
  border-radius: 8px;

  &.auto {
    height: auto;
  }

  &.small {
    height: calc(100vh - 180px);
  }

  &.large {
    height: calc(100vh - 360px);
  }

  &.medium {
    height: calc(100vh - 260px);
  }
}
