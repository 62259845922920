
.moreAction {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}

.weightMargin {
  p {
    margin-bottom: 0;
  }
}

.no-margin-bottom {
  input {
    margin-bottom: 0 !important;
  }
}

.tableSize {
  width: 100%;
}

.tabLabel,
.tabCode {
    width: 34%;
}


//---- Tab switch historic
.ant-tabs {
  max-height: calc(100vh - 360px);
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  margin-bottom: 0;
  padding: 16px 8px;
}

.ant-tabs-nav .ant-tabs-tab:active {
  color: white;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: white;
}

.ant-tabs-nav .ant-tabs-tab-active {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(135deg, #0d5257 0%, #00b2a9 100%);
  color: white;
  cursor: pointer;
  width: 100%;

  &:after {
    content: "\ea3c";
    font-family: "Acanexia";
    color: white;
    margin-right: 8px;
    margin-left: 24px;
  }
}

.ant-tabs-nav .ant-tabs-tab {
  &:after {
    content: "\ea3c";
    font-family: "Acanexia";
    color: white;
    margin-right: 8px;
    margin-left: 24px;
  }
}

.ant-tabs-nav:hover {
  background: linear-gradient(135deg, #0d5257 0%, #00b2a9 100%);
  color: white;
  cursor: pointer;
  width: 100%;
}
