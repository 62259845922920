// Table Skin //
// ----------//
table {
  table-layout: inherit;
  margin: 0 auto;
  font-size: 14rem;

  td {
    font-size: 1.4rem !important;
  }

  thead {
    border-bottom: rem-calc(2px) solid $app-primary;

    th {
      font-size: 1.4rem !important;
    }
  }

  tr {
    &.selected {
      background-color: $app-primary;

      td {
        color: white !important;
        font-size: 1.4rem !important;
      }
    }
  }


  tbody {
    td {
      font-size: 1.4rem
    }
  }
}

//---- Table Container Size //
.tableContainer {
  overflow: hidden;
  overflow-y: auto;

  &.small {
    height: calc(100vh - 180px);
  }

  &.medium {
    height: calc(100vh - 360px);
  }

  &.large {
    height: calc(100vh - 460px);
  }
}


//---- Table Content Size //
.large-size {
  width: 24%;
}

.medium-size {
  width: 12%;
}

.small-size {
  width: 6%;
}



//---- Table Icon States //
.tableState {
  padding: 8px;
  border-radius: 100%;
  color: white;
  font-size: 10px;

  &.success {
    background-color: $app-success;
  }

  &.error {
    background-color: $app-error;
  }
}


//---- Pagination Style //
.paginationContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  button {
    padding: 4px 16px;
  }

  &-text {
    padding: 0 8px;
    font-weight: 700;
    color: $app-secondary;
  }
}


// SubTable Skin //
// ----------//

.subTableContainer {
  box-shadow: 0 0 20px rgba($app-secondary, 0.3);
  background-color: #e7e2e280;

  &.selected {
    background-color: #e7e2e280;
  }
}

.subTable {
  thead {
    background: white;
    color: $app-primary;
  }

  tbody {
    tr {
      background: rgba($app-secondary, 0.3);

      td {
        background: white;
      }
    }
  }
}
