.nav-cover {
    display: none;
}

.side-nav {
    white-space: nowrap;
    i {
        flex-shrink: 0;
    }
}

.MuiTableCell-body {
    overflow: hidden;
    text-overflow: ellipsis;
}