// CUSTOM BUTTON
//

// -----------------------------------------------------------------------------
// This file contains all styles related to the BUTTONS component.
// -----------------------------------------------------------------------------

// ================ //
// Adjust Style Foundation
// ================ //


//---- Button normal ----
.button {
  margin: 0;
  transition: 240ms;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 32px;
  color: white;
  line-height: normal;
  outline: none;

  .loading-spinner {
    display: none !important;
  }

  &.is-loading {
    .fa {
      display: none !important;
    }

    .loading-spinner {
      display: inline-block !important;
    }
  }

  span {
    color: white;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;

    &:hover {
      cursor: not-allowed !important;
      opacity: 0.3 !important;
      color: white;
    }
  }

  &:hover {
    transition: 540ms;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .26);
    color: white;
  }

  // ---- Adjust Size

  &.xsmall {
    padding: 0.3em 1.5em;
    font-size: rem-calc(12px);

    &.with-label {
      @media screen and (max-width: 1430px) {

        text-align: center;

        i {
          margin: 0;
        }
      }
    }
  }

  &.small {
    padding: 0.65em 1em;
    font-size: 12px;

    &.with-label {
      @media screen and (max-width: 1430px) {

        text-align: center;

        i {
          font-size: rem-calc(13px);
          margin: 0;
        }
      }
    }
  }

  &.medium {
    padding: 0.75em 1em;
    font-size: 14px;
  }

  &.large {
    padding: 0.85em 1em;
  }

  // ================ //
  // Define color
  // ================ //

  //---- Basic Color

  &.primary {
    background-color: $app-primary;
    color: white;
    box-shadow: 0 16px 24px rgba($app-primary, 0.2);

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      background-color: $app-primary;
      color: white;
    }
  }

  &.secondary {
    background-color: $app-secondary;
    color: white;
    box-shadow: 0 16px 24px rgba($app-secondary, 0.2);

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      background-color: $app-secondary;
      color: white;
    }
  }

  &.grey {
    background-color: $app-extra-light-grey;
    color: white;
    box-shadow: 0 16px 24px rgba($app-extra-light-grey, 0.2);

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      background-color: $app-extra-light-grey;
      color: white;
    }
  }

  &.white {
    background-color: white;
    color: $app-primary;
    box-shadow: 0 16px 24px rgba($app-primary, 0.2);

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      background-color: $app-primary;
      color: white;
    }
  }

  //---- Outline Color

  &.outlinePrimary {
    border:1px solid $app-primary;
    background: transparent;
    color: $app-primary;

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      border:1px solid $app-primary;
    }
  }

  &.outlineSecondary {
    border:1px solid $app-secondary;
    background: transparent;
    color: $app-secondary;

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      border:1px solid $app-secondary;
    }

    span {
      color: $app-secondary;
    }
  }

  &.outlineGrey {
    border: 1px solid $app-extra-light-grey;
    background: transparent;
    color: $app-extra-light-grey;

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      border: 1px solid $app-extra-light-grey;
    }

    i {
      margin-right: 8px;
    }
  }

  &.outlineWhite {
    border: 1px solid white;
    background: transparent;
    color: white;

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      border: 1px solid white;
    }

    i {
      margin-right: 8px;
    }
  }
}


//---- Round button
.roundButton {
  height: 32px !important;
  width: 32px !important;
  border-radius: 100% !important;
  cursor: pointer !important;
  padding: 0 !important;
  outline:none;

  &.roundPrimary {
    background-color: $app-primary;
    color: white;
    box-shadow: 0 16px 24px rgba($app-primary, 0.2);


    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      background-color: $app-secondary;
      color: white;
    }
  }

  &.roundSecondary {
    background-color: $app-secondary;
    color: white;
    box-shadow: 0 16px 24px rgba($app-secondary, 0.2);


    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      background-color: $app-secondary;
      color: white;
    }
  }
}

.ant-btn {
  height: auto;
}

.ant-btn-lg {
  width: auto;
}



//---- Ripple effect CSS-only

@keyframes ripple {
  0% {
    opacity: 0.5;
    transform: scale(0);
  }
  20% {
    transform: scale(.7);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.ripple {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    height: 300px;
    transform: scale(0);
    border-radius: 100%;
    margin-left: -150px;
    margin-top: -150px;
    background: white;
    opacity: 0;
  }

  &:focus:not(:active):after {
    animation: ripple 1s ease-out;
  }
}

