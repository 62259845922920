@import '../../../styles/utils/variables';

.infoButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.incompletAudit {
  width: auto;
  background: rgba($app-error, 0.2);
  color: $app-error;
  border-radius: 4px;
  padding: 8px 16px;
  text-align: left;
  margin-bottom: 16px;

  i {
    margin-right: 8px;
  }
}

.buttonRight {
  text-align: end;
}