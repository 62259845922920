@import '../../styles/utils/variables';

.ant-modal-wrap,
.previsualisation-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.previsualisation-modal-container {
  flex-direction: column;
}

.ant-modal {
  width: 85vw !important;
  position: initial;
}

.ant-modal-content {
  max-height: 70vh;
}

.ant-modal-title {
  font-weight: bold;
}

.ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($app-secondary, 0.2);
  color: $app-secondary;
  height: rem-calc(32px);
  width: rem-calc(32px);
  border-radius: 100%;
  margin: 8px;

  &:hover {
    opacity: 0.8;
  }
}

.ant-modal-body {
  max-height: calc(70vh - 145px);
  overflow: hidden;
  overflow-y: auto;
}


.ant-btn {
  margin: 0;
  transition: 240ms;
  cursor: pointer;
  border-radius: 4px;
  line-height: normal;
  outline: none;
  padding: 0.85em 1em;
  font-size: 14px;
  border: 1px solid $app-secondary;
  background: transparent;
  color: $app-secondary;

  &:hover, &:focus {
    transition: 240ms;
    opacity: 0.6;
    border: 1px solid $app-secondary;
  }


  &.ant-btn-primary {
    background-color: $app-primary;
    color: white;
    border-color: transparent;
    box-shadow: 0 16px 24px rgba($app-primary, 0.2);

    &:hover, &:focus {
      transition: 240ms;
      opacity: 0.6;
      background-color: $app-primary;
      color: white;
    }
  }
}


.previsualisation-modal-container
.previsualisation-modal-content
.close-modal-button {
  top: 12px;
}
